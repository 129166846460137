import {orderBy} from "lodash";

import actionTypes from "~actionTypes";
import {createNamespacer, createReducer} from "~utils/reducers";

const initialState = {
  showAddModifierScreen: false,
  isModifierUpdate: false,
  modifiersListCallInProgress: false,
  modifiersCallError: null,
  modifiers: [],
  createModifierCallInProgress: false,
  createModifierError: null,
  toggleModifierError: "",
  updateModifierValues: null,
  toggleModifierCreationPopup: false,
  createdModifierName: null,
  modifiersRefresh: false,
};

const nameSpace = "BO-MODIFIERS";
export const modifiersNamespacer = createNamespacer(nameSpace);
export const modifiersAT = actionTypes.businessOwner.globalSettings.modifiers;

const handlers = {
  [modifiersNamespacer(modifiersAT.SHOW_HIDE_ADD_MODIFIER_SCREEN)]: (state, action) => {
    return {
      ...state,
      showAddModifierScreen: action.payload,
      isModifierUpdate: action.isUpdate,
      createModifierError: "",
      modifiersCallError: "",
      toggleModifierError: "",
    };
  },

  [modifiersNamespacer(modifiersAT.SET_MODIFIERS_CALL_PROGRESS)]: (state, action) => {
    return {
      ...state,
      modifiersListCallInProgress: action.payload,
    };
  },

  [modifiersNamespacer(modifiersAT.SET_MODIFIERS_CALL_ERROR)]: (state, action) => {
    return {
      ...state,
      modifiersCallError: action.payload,
    };
  },

  [modifiersNamespacer(modifiersAT.SET_MODIFIERS_LIST)]: (state, action) => {
    const sortedModifiers = orderBy(
      action.payload,
      [(modifier) => modifier.name.toLowerCase()],
      ["asc"]
    );
    return {
      ...state,
      modifiersRefresh: true,
      modifiers: sortedModifiers,
    };
  },

  [modifiersNamespacer(modifiersAT.SET_CREATE_MODIFIER_CALL_PROGRESS)]: (
    state,
    action
  ) => {
    return {
      ...state,
      createModifierCallInProgress: action.payload,
    };
  },

  [modifiersNamespacer(modifiersAT.SET_CREATE_MODIFIER_SUCCESS)]: (state, action) => {
    return {
      ...state,
      toggleModifierCreationPopup: action.payload,
      createdModifierName: action.createdModifierName,
    };
  },

  [modifiersNamespacer(modifiersAT.SET_CREATE_MODIFIER_CALL_ERROR)]: (state, action) => {
    return {
      ...state,
      createModifierError: action.payload,
    };
  },

  [modifiersNamespacer(modifiersAT.TOGGLE_MODIFIER_IS_ENABLED)]: (state, action) => {
    const modifiers = [...state.modifiers];
    const {isEnabled, modifierId} = action.payload;
    const index = modifiers.findIndex((modifier) => modifier.modifierId === modifierId);
    if (index !== -1) {
      modifiers[index].isEnabled = isEnabled;
    }

    return {
      ...state,
      modifiers,
    };
  },

  [modifiersNamespacer(modifiersAT.TOGGLE_MODIFIER_ERROR)]: (state, action) => {
    return {
      ...state,
      toggleModifierError: action.payload,
    };
  },

  [modifiersNamespacer(modifiersAT.CLEAR_MODIFIERS)]: (state) => {
    return {
      ...state,
      modifiers: [],
      modifiersCallError: "",
      createModifierError: "",
      toggleModifierError: "",
    };
  },

  [modifiersNamespacer(modifiersAT.SET_UPDATE_VALUES)]: (state, action) => {
    return {
      ...state,
      updateModifierValues: action.payload,
    };
  },

  [modifiersNamespacer(modifiersAT.SET_MODIFIER_REFRESH)]: (state, action) => {
    return {
      ...state,
      modifiersRefresh: action.payload,
    };
  },
};

export default createReducer(initialState, handlers, [nameSpace]);
