import * as Sentry from "@sentry/react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {WebSocket} from "partysocket";
import ReconnectingWebSocket from "partysocket/ws";
import {useCallback, useEffect, useRef} from "react";

import {WebsocketMessengerMessageType} from "~components/business-owner/messenger/constants";

import {WebSocketMessage} from "~types/messenger";
import {REACT_ENV, VITE_ENV_TYPES} from "~utils/config";

import {useInvalidateConversationList} from "./useConversationList";
import {useInvalidateConversationMessages} from "./useConversationMessages";
import useMessengerConnectionUrl from "./useMessengerConnectionUrl";
import {useInvalidateUnreadMessagesCount} from "./useUnreadMessagesCount";

const PING_INTERVAL = 420000; // 7 minutes
const MAX_RETRIES_WITH_UPDATED_URL = 5;

export const useMessengerWebSocket = () => {
  const {messengerAccelerate} = useFlags();
  const wsConnectionRef = useRef<ReconnectingWebSocket | null>(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const retryCountRef = useRef<number>(0);

  const {data: connectionData, refetch: refetchMessengerConnectionUrl} =
    useMessengerConnectionUrl();

  const invalidateConversationsMessages = useInvalidateConversationMessages();
  const invalidateUnreadMessagesCount = useInvalidateUnreadMessagesCount();
  const invalidateConversationsList = useInvalidateConversationList();

  const clearPingInterval = () => {
    clearInterval(pingIntervalRef.current || undefined);
    pingIntervalRef.current = null;
  };

  const startPingInterval = useCallback(() => {
    if (wsConnectionRef.current?.url) {
      clearPingInterval();

      const channelId = new URL(wsConnectionRef.current.url).searchParams.get(
        "channelId"
      );
      const pingMessage = {
        channelId,
        data: JSON.stringify({type: WebsocketMessengerMessageType.PING}),
      };

      pingIntervalRef.current = setInterval(() => {
        wsConnectionRef.current?.send(JSON.stringify(pingMessage));
      }, PING_INTERVAL);
    }
  }, []);

  const setupWebSocket = useCallback(
    (url: string) => {
      wsConnectionRef.current?.close();

      const wsConnection = new WebSocket(url, [], {
        WebSocket: window.WebSocket,
        connectionTimeout: 4000,
        minReconnectionDelay: 2000,
        maxReconnectionDelay: 60000,
        maxEnqueuedMessages: 10,
        debug: REACT_ENV === VITE_ENV_TYPES.LOCAL,
      });

      wsConnectionRef.current = wsConnection;

      wsConnection.onopen = () => {
        startPingInterval();
      };

      wsConnection.onmessage = (event) => {
        const parsedData: WebSocketMessage = JSON.parse(event.data);

        startPingInterval();

        if (parsedData.type === WebsocketMessengerMessageType.INBOUND_SMS) {
          invalidateConversationsList();
          invalidateConversationsMessages();
          invalidateUnreadMessagesCount();
        }

        if (parsedData.type === WebsocketMessengerMessageType.SMS_STATUS_CALLBACK) {
          invalidateConversationsList();
          invalidateConversationsMessages();
        }
      };

      wsConnection.onerror = (errorEvent) => {
        const error = errorEvent.error || new Error(errorEvent.message);

        Sentry.captureException(error);

        if (retryCountRef.current < MAX_RETRIES_WITH_UPDATED_URL) {
          refetchMessengerConnectionUrl().then(({data}) => {
            if (data?.url) {
              retryCountRef.current += 1;
              setupWebSocket(data.url);
            }
          });
        }
      };
    },
    [
      startPingInterval,
      refetchMessengerConnectionUrl,
      invalidateConversationsList,
      invalidateConversationsMessages,
      invalidateUnreadMessagesCount,
    ]
  );

  useEffect(() => {
    if (connectionData?.url && messengerAccelerate) {
      if (wsConnectionRef.current) {
        wsConnectionRef.current.close();
      }
      setupWebSocket(connectionData.url);
    }

    return () => {
      wsConnectionRef.current?.close();
      wsConnectionRef.current = null;
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
  }, [connectionData?.url, messengerAccelerate, setupWebSocket]);
};
