export const GUEST_ROUTES = {
  login: "/login",
  resetPassword: "/password-reset",
  forgotPassword: "/forgot-password",
  default: "/",
};

export const DASHBOARD_ROOT_ROUTE = "/dashboard";
export const REPORTS_ROOT_ROUTE = `${DASHBOARD_ROOT_ROUTE}/reports`;
export const PRE_BUILT_REPORT_ROUTE = `${REPORTS_ROOT_ROUTE}/pre-built`;
export const CUSTOM_REPORT_ROUTE = `${REPORTS_ROOT_ROUTE}/custom`;
export const CONFIGURED_REPORT_ROUTE = `${REPORTS_ROOT_ROUTE}/configured`;
