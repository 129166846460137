import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import {PRICE_UNIT_LABELS, PRICING_TYPES} from "~constants";
import type {LocationProduct} from "~types/products/InventoryItem";

export const getProductStatus = (product) => {
  const [numOfFeaturedLocations, numOfFeaturedLocationsWithStock] = (
    product.inventoryItems ?? []
  ).reduce(
    (result, item) => {
      if (item.isFeatured && item.storeId) {
        result[0]++;
        if (Number(item.quantity) > 0) {
          result[1]++;
        }
      }
      return result;
    },
    [0, 0]
  );

  if (numOfFeaturedLocationsWithStock === numOfFeaturedLocations) {
    return "In stock";
  }

  if (numOfFeaturedLocationsWithStock === 0) {
    return "Out of Stock";
  }

  return `In stock at ${numOfFeaturedLocationsWithStock}/${numOfFeaturedLocations} locations`;
};

export const updateProductPriceByLocation = ({product, storeId, field, value}) => {
  return {
    ...product,
    inventoryItems: (product.inventoryItems ?? []).map((item) =>
      item.storeId === storeId ? {...item, [field]: value} : item
    ),
  };
};

export const updateProductListFeaturedPrices = ({productsList, product}) => {
  return productsList.map((_product) => {
    if (_product.id === product.id) {
      return {
        ..._product,
        featuredPrices: [
          ...new Set(
            (product.inventoryItems ?? [])
              .filter((item) => item.isFeatured)
              .map((item) => item.price)
          ),
        ],
      };
    } else {
      return _product;
    }
  });
};

export const setOrAddInventoryItem = ({product, item}) => {
  const inventoryItems = [...(product.inventoryItems ?? [])];

  const itemIndex = inventoryItems.findIndex((_item) => _item.id === item.id);
  if (itemIndex !== -1) {
    inventoryItems[itemIndex] = item;
  } else {
    inventoryItems.push(item);
  }

  return {
    ...product,
    inventoryItems,
  };
};

export const applyInventoryItemToIds = ({
  product,
  inventoryItemIdsToUpdate,
  referenceItem,
}) => {
  return {
    ...product,
    inventoryItems: (product.inventoryItems ?? []).map((item) => {
      if (inventoryItemIdsToUpdate.includes(item.id)) {
        return {
          ...item,
          minPrice: Number(referenceItem.minPrice),
          minQty: Number(referenceItem.minQty),
          price: Number(referenceItem.price),
          isTaxable: referenceItem.isTaxable,
        };
      } else {
        return item;
      }
    }),
  };
};

/**
 * Map server products for `PriceListItem` component
 */
export const mapLocationProducts = (products: LocationProduct[]) =>
  sortBy(
    products
      .filter((product) => !product.isDeleted)
      .map((product) => ({
        title: product.productName,
        isSelected: product.isFeatured,
        priceUnit: PRICE_UNIT_LABELS[PRICING_TYPES.FIXED_PRICE],
        imageUrl: product.productImage,
        ...product,
      })),
    ({title}) => title.toLowerCase()
  );

export const groupProductsByCategory = <
  T extends {categoryName: string} = {categoryName: string}
>(
  products: T[]
) => {
  const productsByCategory = groupBy(products ?? [], "categoryName");
  const productCategories = Object.keys(productsByCategory);
  return {productsByCategory, productCategories};
};
