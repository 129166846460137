import moment from "moment";

import {
  availableSections,
  CONNECT_SECTION_KEY,
  CUSTOMERS_SECTION_KEY,
  DELIVERY_SECTION_KEY,
  MACHINES_SECTION_KEY,
  ORDERS_SECTION_KEY,
  PRODUCTS_SECTION_KEY,
  PROMOTIONS_SECTION_KEY,
  REVENUE_SECTION_KEY,
  VOLUME_SECTION_KEY,
  WIDGETS_SESSION_STORAGE_KEY,
} from "~components/business-owner/analytics-dashboard/constants";

import {getSessionStorageOrDefault} from "~hooks/useSessionStorage";

import actionTypes from "~actionTypes";
import {DateRangeTypes} from "~constants/dates/dateRange";
import {createNamespacer, createReducer} from "~utils/reducers";

const nameSpace = "BUSINESS_OWNER_ANALYTICS_DASHBOARD";
const dashboardAT = actionTypes.businessOwner.analyticsDashboard;

const BoAnalyticsDashboardNamespacer = createNamespacer(nameSpace);
const BoDashboardNamespacer = createNamespacer("BUSINESS_OWNER_DASHBOARD");

const today = moment();

const defaultDashboardData = {
  customersGroup: {
    groupData: {
      averageCustomerLifetimeWidgetData: {
        averageLifetime: 0,
        isActive: true,
        isLoading: false,
        percentageChange: 0,
      },
      averageDaysBetweenVisitsWidgetData: {
        averageDays: 0,
        isActive: true,
        isLoading: false,
        percentageChange: 0,
      },
      customerVisitsWidgetData: {
        chartData: [],
        isActive: true,
        isLoading: false,
        percentageChange: 0,
        totalVisits: 0,
      },
      newCustomersPerDayWidgetData: {
        chartData: [],
        isActive: true,
        isLoading: false,
        percentageChange: 0,
        totalCustomers: 0,
      },
    },
    key: CUSTOMERS_SECTION_KEY,
    isActive: availableSections.includes(CUSTOMERS_SECTION_KEY),
  },
  deliveryGroup: {
    groupData: {
      popularDeliveryWindowWidgetData: {
        chartData: [],
        chartLabels: [
          "0",
          "2",
          "4",
          "6",
          "8",
          "10",
          "12",
          "14",
          "16",
          "18",
          "20",
          "22",
          "24",
        ],
        isActive: true,
      },
    },
    key: DELIVERY_SECTION_KEY,
    isActive: availableSections.includes(DELIVERY_SECTION_KEY),
  },
  machinesGroup: {
    groupData: {
      numberOfCardStartsWidgetData: {
        isActive: true,
        isLoading: false,
        numberOfCardStarts: 0,
        percentageChange: 0,
      },
      numberOfCoinStartsWidgetData: {
        isActive: true,
        isLoading: false,
        numberOfCoinStarts: 0,
        percentageChange: 0,
      },
    },
    key: MACHINES_SECTION_KEY,
    isActive: availableSections.includes(MACHINES_SECTION_KEY),
  },
  ordersGroup: {
    groupData: {
      averageDailyOrderVolumeWidgetData: {
        chartData: [0, 0, 0, 0, 0, 0, 0],
        chartLabels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        isActive: true,
        isLoading: false,
      },
    },
    key: ORDERS_SECTION_KEY,
    isActive: availableSections.includes(ORDERS_SECTION_KEY),
  },
  volumeGroup: {
    key: VOLUME_SECTION_KEY,
    isActive: availableSections.includes(VOLUME_SECTION_KEY),
  },
  productsGroup: {
    key: PRODUCTS_SECTION_KEY,
    isActive: availableSections.includes(PRODUCTS_SECTION_KEY),
  },
  promotionsGroup: {
    groupData: {
      orderTotalsWithPromoCodesWidgetData: {
        chartData: [],
        isActive: true,
        isLoading: false,
      },
      topPromoCodesWidgetData: {
        individualDetails: [],
        individualDetailsPageData: {
          page: 1,
          pageSize: 10,
        },
        isActive: true,
        isLoading: false,
        locationDetails: [],
        locationDetailsPageSize: {
          page: 1,
          pageSize: 10,
        },
        modalDataLoading: false,
        topItems: [],
      },
    },
    key: PROMOTIONS_SECTION_KEY,
    isActive: availableSections.includes(PROMOTIONS_SECTION_KEY),
  },
  revenueGroup: {
    groupData: {
      totalRevenueBreakdownByPaymentMethodWidgetData: {
        chartData: [],
        isActive: true,
        isLoading: false,
      },
    },
    key: REVENUE_SECTION_KEY,
    isActive: availableSections.includes(REVENUE_SECTION_KEY),
  },
  connectGroup: {
    key: CONNECT_SECTION_KEY,
    isActive: availableSections.includes(CONNECT_SECTION_KEY),
  },
};

const getProcessedDashboardData = (dashboardData) => {
  for (const groupName in dashboardData) {
    const key = dashboardData[groupName].key;

    if (key in sessionStorageDashboardData) {
      dashboardData[groupName].isActive =
        availableSections.includes(key) && (sessionStorageDashboardData[key] ?? true); // show section if it wasn't previously saved to the session storage
    }
  }
  return dashboardData;
};

const sessionStorageDashboardData = getSessionStorageOrDefault(
  WIDGETS_SESSION_STORAGE_KEY
);

const dashboardData = sessionStorageDashboardData
  ? getProcessedDashboardData(defaultDashboardData)
  : defaultDashboardData;

const initialState = {
  selectedLocations: [],
  allLocations: {
    regions: [],
    locations: [],
    storesWithoutRegions: [],
    needsRegions: false,
  },
  totalLocations: [],
  dashboardData,
  filterPeriod: {
    type: DateRangeTypes.TODAY,
    endDate: today.clone().endOf("day"),
    startDate: today.clone().startOf("day"),
  },
};

const handlers = {
  // Location Handlers

  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.RESET_DASHBOARD_DATA)]:
    () => {
      return {
        ...initialState,
      };
    },

  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_ALL_LOCATIONS)]: (
    state,
    action
  ) => {
    let selectedLocations = action.payload?.locations?.map((location) => location.id);
    let totalLocations = action.payload?.locations?.map((location) => location.id);

    let assignedRegions = [];
    if (!action.payload?.isOwner) {
      assignedRegions = action.payload?.regions
        ?.map((region) => {
          let assignedDistricts = region.districts
            .map((district) => {
              let assignedStores = district?.stores?.filter((store) =>
                selectedLocations.includes(store.id)
              );
              return assignedStores?.length
                ? {...district, stores: assignedStores}
                : null;
            })
            ?.filter((district) => district);
          return assignedDistricts?.length
            ? {...region, districts: assignedDistricts}
            : null;
        })
        ?.filter((region) => region);
    } else {
      assignedRegions = action.payload?.regions;
    }

    const assignedStoresWithoutRegions = action.payload?.storesWithoutRegions?.filter(
      (store) => selectedLocations.includes(store?.id)
    );

    return {
      ...state,
      allLocations: {
        ...action.payload,
        regions: assignedRegions,
        storesWithoutRegions: assignedStoresWithoutRegions,
      },
      selectedLocations: selectedLocations || [],
      totalLocations: totalLocations || [],
    };
  },

  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_SELECTED_LOCATIONS)]: (
    state,
    action
  ) => {
    return {
      ...state,
      selectedLocations: action.payload,
    };
  },

  // Dashboard Section Visibility Handlers
  [BoAnalyticsDashboardNamespacer(dashboardAT.CUSTOMERS_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        customersGroup: {
          ...state.dashboardData.customersGroup,
          isActive: action.payload ?? state.dashboardData.customersGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.DELIVERY_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        deliveryGroup: {
          ...state.dashboardData.deliveryGroup,
          isActive: action.payload ?? state.dashboard.deliveryGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.MACHINES_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        machinesGroup: {
          ...state.dashboardData.machinesGroup,
          isActive: action.payload ?? state.dashboardData.machinesGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.ORDERS_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        ordersGroup: {
          ...state.dashboardData.ordersGroup,
          isActive: action.payload ?? state.dashboardData.ordersGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.VOLUME_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        volumeGroup: {
          ...state.dashboardData.volumeGroup,
          isActive: action.payload ?? state.dashboardData.volumeGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.PRODUCTS_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        productsGroup: {
          ...state.dashboardData.productsGroup,
          isActive: action.payload ?? state.dashboardData.productsGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.PROMOTIONS_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        promotionsGroup: {
          ...state.dashboardData.promotionsGroup,
          isActive: action.payload ?? state.dashboardData.promotionsGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.REVENUE_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        revenueGroup: {
          ...state.dashboardData.revenueGroup,
          isActive: action.payload ?? state.dashboardData.revenueGroup.isActive,
        },
      },
    };
  },

  [BoAnalyticsDashboardNamespacer(dashboardAT.CONNECT_TOGGLE_DATA_CALL_SUCCEEDED)]: (
    state,
    action
  ) => {
    return {
      ...state,
      dashboardData: {
        ...state.dashboardData,
        connectGroup: {
          ...state.dashboardData.connectGroup,
          isActive: action.payload ?? state.dashboardData.connectGroup.isActive,
        },
      },
    };
  },

  // Dashboard Handlers
  [BoAnalyticsDashboardNamespacer(dashboardAT.FILTER_DATA_PERIOD)]: (state, action) => {
    return {
      ...state,
      filterPeriod: action.payload ?? state.filterPeriod,
    };
  },
};

export default createReducer(initialState, handlers, [nameSpace]);
