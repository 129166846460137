export const requiredFieldErrors = {
  NAME: "Name is a required field",
  FIRST_NAME: "First name is a required field",
  LAST_NAME: "Last name is a required field",
  EMAIL: "Email is a required field",
  EMPLOYEE_CODE: "Employee code is required",
  PHONE: "Phone is a required field",
  COMPANY_NAME: "Company Name is a required field",
  ADDRESS: "Address is a required field",
  CITY: "City is a required field",
  STATE: "State is a required field",
  ZIP_CODE: "Zip code is a required field",
  NEEDS_REGION: "Needs region is a required field",
  DISTRICT: "District is a required field",
};

export const invalidFieldErrors = {
  EMAIL: "Invalid email address",
  PHONE: "Invalid phone number",
  BIRTHDAY: "Invalid birthday value",
  ZIP_CODE: "Invalid zip code",
};

export const typeFieldErrors = {
  EMPLOYEE_CODE: "Invalid employee code - must be a number",
};

export const lengthFieldErrors = {
  PHONE_10: `${invalidFieldErrors.PHONE}. The phone number must be 10 digits.`,
};

export const addressErrors = {
  hasActiveSubscription: "Address used for recurring order",
  hasServiceOrders: "Address used for service order",
};

const suggestion = "Please correct and retry";
const withSuggestion = (error: string) => `${error}. ${suggestion}`;

export const invalidFieldErrorsWithSuggestion = Object.keys(invalidFieldErrors).reduce(
  (acc, key) => ({
    ...acc,
    [key]: withSuggestion(invalidFieldErrors[key]),
  }),
  {}
);

export const errorMessages = Object.values({
  ...requiredFieldErrors,
  ...invalidFieldErrors,
  ...typeFieldErrors,
  ...lengthFieldErrors,
});
