import {useFlags, useLDClient} from "launchdarkly-react-client-sdk";
import React, {useCallback, useEffect, useMemo} from "react";
import {Route} from "react-router-dom";

import {BillingSuspensionBlockingModal} from "~components/commons/BillingSuspensionBlockingModal/BillingSuspensionBlockingModal";

import {SESSION_ENV_KEY, VITE_ENV_TYPES} from "~utils/config";
import {getParsedLocalStorageData} from "~utils/functions";

const env = import.meta.env.VITE_ENV;

/*
  This wrapped Route is used to identify LD user 
  and to display BillingSuspensionBlockingModal if "billingAccountSuspendedModal" feature flag is enabled
*/
const RouteWithLDUser = ({component: Component, ...rest}) => {
  const {billingAccountSuspendedModal} = useFlags();
  const LDClient = useLDClient();
  const localStorageData = useMemo(() => getParsedLocalStorageData(SESSION_ENV_KEY), []);

  const registerLaunchDarklyUser = useCallback(() => {
    const user = {
      key: localStorageData?.email,
      email: localStorageData?.email,
      custom: {businessId: localStorageData?.business?.id},
    };

    void LDClient?.identify(user);
  }, [LDClient, localStorageData]);

  useEffect(() => {
    registerLaunchDarklyUser();
  }, [registerLaunchDarklyUser]);

  const shouldBlockAccess = billingAccountSuspendedModal && env !== VITE_ENV_TYPES.TESTS;

  return (
    <Route
      {...rest}
      component={shouldBlockAccess ? BillingSuspensionBlockingModal : Component}
    />
  );
};

export default RouteWithLDUser;
