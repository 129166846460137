export type NullableProps<T> = {[K in keyof T]: T[K] | null};

export type NonNullableProps<T> = {[K in keyof T]: NonNullable<T[K]>};

export type Nullish = null | undefined;

export type ObjectValues<T> = T[keyof T];

export type NotNullish<T> = T extends Nullish ? never : T;

/**
 * Type guard for narrowing a value that could be nullish to nullish.
 */
export function isNullish<T>(value: Nullish | T): value is Nullish {
  return value === null || value === undefined;
}

/**
 * Type guard for eliminating null and undefined from a type.
 *
 * Especially useful for array filters.
 */
export function isNotNullish<T>(value: Nullish | T): value is T {
  return !isNullish(value);
}
