import {PayloadAction} from "@reduxjs/toolkit";

import actionTypes from "../actionTypes";
import {createNamespacer, createReducer, ReducerHandlers} from "../utils/reducers";

export const sessionNamespacer = createNamespacer("SESSION");

export type SessionState = {
  isLoggedIn: boolean;
  business?: {
    id: number;
    name: string;
    uuid: string;
    createdAt: string;
  };
  email?: string;
  firstName?: string;
  lastName?: string;
  roleName?: string;
  teamMemberId?: number;
  token?: string;
  userId?: number;
  uuid?: string;
  intercomHash?: string;
};

const initialState: SessionState = {
  isLoggedIn: false,
};

const handlers: ReducerHandlers<SessionState> = {
  [sessionNamespacer(actionTypes.session.SET_SESSION)]: (
    state,
    action: PayloadAction<SessionState>
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },

  [sessionNamespacer(actionTypes.session.REMOVE_SESSION)]: (
    state,
    action: PayloadAction<{value: boolean}>
  ) => {
    const isLoggedIn = action.payload.value;

    return {
      isLoggedIn,
    };
  },
};

export default createReducer(initialState, handlers, ["SESSION"]);
