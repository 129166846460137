import {PayloadAction} from "@reduxjs/toolkit";

export type ReducerHandlers<State> = {
  [key: string]: (state: State, action: PayloadAction<unknown>) => State;
};

export const createNamespacer = (namespace: string) => {
  return (actionType: string) => {
    return `${namespace}:${actionType}`;
  };
};

function getNamespace(actionType: string) {
  return actionType.split(":")[0];
}

export function createReducer<State>(
  initialState: State,
  handlers: ReducerHandlers<State>,
  namespaces: string[] = []
) {
  return (state: State = initialState, action: PayloadAction<unknown>): State => {
    if (namespaces.includes(getNamespace(action.type))) {
      if (handlers[action.type]) {
        return handlers[action.type](state, action);
      } else {
        return state;
      }
    }
    return state;
  };
}
