import * as Sentry from "@sentry/react";
import React from "react";

/**
 * An HoC to capture and send every error to Sentry
 *
 * This is a class-based component because React does not currently support
 * functional error boundary components
 */
class ErrorBoundary extends React.Component {
  componentDidCatch(error, {componentStack}) {
    // optional chaining because error can be anything https://beta.reactjs.org/reference/react/Component#componentdidcatch-parameters
    if (error?.message?.includes("Failed to fetch dynamically imported module")) {
      // This error occurs if a user tries to navigate client-side immediately after we've deployed a new build
      // The issue is the user is trying to load assets that no longer exist.
      // https://stackoverflow.com/a/74057337

      // For our case, the simple fix for handling this edge case is to just reload the page
      window.location.reload();
      // we don't report this error to sentry, as we're handling it explicitly already.
      return;
    }
    Sentry.captureException(error, {contexts: {react: {componentStack}}});
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
