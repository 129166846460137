import React, {type ReactNode} from "react";
import toast, {type ToastOptions} from "react-hot-toast";

import errorIcon from "~assets/images/error.svg";
import exitIcon from "~assets/images/Icon_Exit_Small_Side_Panel.svg";
import successIcon from "~assets/images/success.svg";

const toastDuration = 3000;

const toastStyles = {
  style: {
    backgroundColor: "unset",
    boxShadow: "none",
    width: "auto",
    maxWidth: "unset",
    display: "flex",
    justifyContent: "center",
  },
  duration: toastDuration,
  icon: null,
};

interface ToastParam {
  content: string | ReactNode;
  options?: ToastOptions;
}

export const callErrorToast = ({content, options}: ToastParam) =>
  toast(
    (t) => (
      <div className="toast-wrapper">
        <div className="toast-container toast-success">
          <img className="toast-icon" src={errorIcon} alt="Error" />
          <div className="toast-message">{content}</div>
          <img
            className="toast-close"
            src={exitIcon}
            onClick={() => toast.dismiss(t.id)}
            alt="Close"
          />
        </div>
      </div>
    ),
    {...toastStyles, ...options}
  );

export const callSuccessToast = ({content, options}: ToastParam) =>
  toast(
    ({id}) => (
      <div className="toast-wrapper">
        <div className="toast-container toast-error">
          <img className="toast-icon" src={successIcon} alt="Success" />
          <div className="toast-message">{content}</div>
          <img
            className="toast-close"
            src={exitIcon}
            onClick={() => toast.dismiss(id)}
            alt="Close"
          />
        </div>
      </div>
    ),
    {...toastStyles, ...options}
  );
