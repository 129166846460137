import actionTypes from "~actionTypes";
import {createNamespacer, createReducer} from "~utils/reducers";

const initialState = {
  activeTab: "Laundry",
  categoriesDetailsUpdateError: "",
  subcategories: [],
  serviceCategoryTypeId: null,
  activeSubcategory: [],
  turnaroundTime: 48,
  turnaroundTimeUpdateInProgress: false,
  error: "",
  subcategoryError: {},
  turnAroundTimeUpdateError: "",
};

const nameSpace = "BO-CATEGORIES";
const categoriesNamespacer = createNamespacer(nameSpace);
const categoriesAT = actionTypes.businessOwner.globalSettings.categories;

const handlers = {
  [categoriesNamespacer(categoriesAT.SET_ACTIVE_TAB)]: (state, action) => {
    return {
      ...state,
      activeTab: action.payload,
      categoriesDetailsUpdateError: initialState.categoriesDetailsUpdateError,
    };
  },
  [categoriesNamespacer(categoriesAT.SET_SUBCATEGORIES)]: (state, action) => {
    return {
      ...state,
      serviceCategoryTypeId: action.payload?.serviceCategoryTypeId,
      subcategories: action.payload?.subcategories,
    };
  },
  [categoriesNamespacer(categoriesAT.SET_TURNAROUND_TIME)]: (state, action) => {
    return {
      ...state,
      turnaroundTime: action.payload,
    };
  },
  [categoriesNamespacer(categoriesAT.UPDATE_SUBCATEGORY)]: (state, action) => {
    return {
      ...state,
      activeSubcategory: action.payload,
    };
  },
  [categoriesNamespacer(categoriesAT.SET_TURNAROUND_TIME_ERROR)]: (state, action) => {
    return {
      ...state,
      turnAroundTimeUpdateError: action.payload,
    };
  },
  [categoriesNamespacer(categoriesAT.SET_TURNAROUND_TIME_UPDATE_IN_PROGRESS)]: (
    state,
    action
  ) => {
    return {
      ...state,
      turnaroundTimeUpdateInProgress: action.payload,
    };
  },
  [categoriesNamespacer(categoriesAT.SET_SUBCATEGORIES_ERROR)]: (state, action) => {
    return {
      ...state,
      subcategoryError: action.payload || {},
    };
  },
  [categoriesNamespacer(categoriesAT.SET_SUBCATEGORIES_LOADING)]: (state, action) => {
    return {
      ...state,
      subcategoriesLoading: action.payload,
    };
  },
  [categoriesNamespacer(categoriesAT.SET_SUBCATEGORY_UPDATING)]: (state, action) => {
    return {
      ...state,
      subcategoryUpdating: action.payload,
    };
  },
};

export default createReducer(initialState, handlers, [nameSpace]);
