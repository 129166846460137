import {PayloadAction} from "@reduxjs/toolkit";

import actionTypes from "~actionTypes";
import type {Region} from "~types/districts/Region";
import type {Location, LocationId} from "~types/locations/Location";
import {isNotNullish} from "~types/typeUtils";
import {createNamespacer, createReducer, ReducerHandlers} from "~utils/reducers";

const BoDashboardNamespacer = createNamespacer("BUSINESS_OWNER_DASHBOARD");

type AllLocations = {
  isOwner: boolean;
  locations: Location[];
  needsRegions: boolean;
  regions: Region[];
  storesWithoutRegions: Location[];
};

type DashboardState = {
  locationList: Location[];
  locationCallInProgress: boolean;
  isLocationsEmpty: boolean;
  selectedLocation: {
    label: string;
    value: LocationId;
  } | null;
  selectedLocations: LocationId[];
  allLocations: AllLocations;
  totalLocations: LocationId[];
};

const initialState: DashboardState = {
  locationList: [],
  locationCallInProgress: false,
  isLocationsEmpty: false,
  selectedLocation: null,
  selectedLocations: [],
  allLocations: {
    regions: [],
    locations: [],
    storesWithoutRegions: [],
    needsRegions: false,
    isOwner: false,
  },
  totalLocations: [],
};

const handlers: ReducerHandlers<DashboardState> = {
  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_LOCATIONS_LIST)]: (
    state,
    action: PayloadAction<Location[]>
  ) => {
    if (state.selectedLocation === null && action.payload && action.payload.length > 0) {
      return {
        ...state,
        locationList: action.payload,
        selectedLocation: {
          label: action.payload[0].address,
          value: action.payload[0].id,
        },
      };
    } else {
      return {
        ...state,
        locationList: action.payload,
      };
    }
  },
  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_IS_LOCATIONS_EMPTY)]: (
    state,
    action: PayloadAction<boolean>
  ) => {
    return {
      ...state,
      isLocationsEmpty: action.payload,
    };
  },

  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_SELECTED_LOCATION)]: (
    state,
    action: PayloadAction<{label: string; value: LocationId}>
  ) => {
    return {
      ...state,
      selectedLocation: action.payload,
    };
  },
  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_LOCATION_CALL_PROGRESS)]:
    (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        locationCallInProgress: action.payload,
      };
    },

  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.RESET_DASHBOARD_DATA)]:
    () => {
      return {
        ...initialState,
      };
    },

  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_ALL_LOCATIONS)]: (
    state,
    action: PayloadAction<AllLocations>
  ) => {
    const selectedLocations = action.payload?.locations?.map((location) => location.id);
    const totalLocations = action.payload?.locations?.map((location) => location.id);

    let assignedRegions: Region[] = [];
    if (!action.payload?.isOwner) {
      assignedRegions = action.payload?.regions
        ?.map((region) => {
          const assignedDistricts = region.districts
            .map((district) => {
              const assignedStores = district?.stores?.filter((store) =>
                selectedLocations.includes(store.id)
              );
              return assignedStores?.length
                ? {...district, stores: assignedStores}
                : null;
            })
            ?.filter(isNotNullish);
          return assignedDistricts?.length
            ? {...region, districts: assignedDistricts}
            : null;
        })
        ?.filter(isNotNullish);
    } else {
      assignedRegions = action.payload?.regions;
    }

    const assignedStoresWithoutRegions = action.payload?.storesWithoutRegions?.filter(
      (store) => selectedLocations.includes(store?.id)
    );

    return {
      ...state,
      allLocations: {
        ...action.payload,
        regions: assignedRegions,
        storesWithoutRegions: assignedStoresWithoutRegions,
      },
      selectedLocations: selectedLocations || [],
      totalLocations: totalLocations || [],
    };
  },

  [BoDashboardNamespacer(actionTypes.businessOwner.dashboard.SET_SELECTED_LOCATIONS)]: (
    state,
    action: PayloadAction<number[]>
  ) => {
    return {
      ...state,
      selectedLocations: action.payload,
    };
  },
};

export default createReducer(initialState, handlers, ["BUSINESS_OWNER_DASHBOARD"]);
