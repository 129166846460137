// Sentry (https://sentry.io/welcome/) is used to capture and alert on errors so that we can be more proactive about finding and fixing issues
import * as FullStory from "@fullstory/browser";
import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/react";

import {errorMessages} from "~constants/validation/errors";
import {FULLSTORY_ORG_ID, SENTRY_ORG_SLUG} from "~utils/config";

export function initSentryFullstory() {
  FullStory.init({orgId: FULLSTORY_ORG_ID});
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_KEY,
    integrations: [
      new Sentry.BrowserTracing(),
      new SentryFullStory(SENTRY_ORG_SLUG, {client: FullStory}),
    ],
    tracesSampleRate: 0.5,
    environment: import.meta.env.VITE_ENV,
    release: import.meta.env.VITE_COMMIT_HASH,
    ignoreErrors: [
      // Validation errors
      ...errorMessages,
      /ValidationError/i,
      /Passwords must match/i,

      // Stripe errors
      /Your card has expired./i,
      /Your card has been declined./i,
      /Your card has insufficient funds./i,

      // Network errors
      /A network error occurred/i,
      /network error (Error)/i,
      /timeout of 0ms exceeded/i,
      /Request limit expired/i,
      /Network Error/i,
      /Network request failed/i,

      // 4xx errors
      /Request failed with status code [4][0-9][0-9]/i,

      // Request aborted errors
      /Request aborted/i,
    ],
    denyUrls: [
      // Browser extensions
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /safari-(web-)?extension:/i,

      // Intercom CDN errors
      /js\.intercomcdn\.com/i,
    ],
  });
}
