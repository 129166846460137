import * as Sentry from "@sentry/react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useFlags} from "launchdarkly-react-client-sdk";

import {MessengerQueryKeys} from "~components/business-owner/messenger/constants";

import {fetchUnreadConversationsCount} from "~api/business-owner/messenger";
import {ApiError} from "~types/api";
import {callErrorToast} from "~utils/toasts";

export interface UseUnreadMessagesCountHookResult {
  unreadConversationsCount: number;
  isUnreadConversationsCountLoading: boolean;
  unreadConversationsCountError: ApiError | null;
  refetchUnreadConversationsCount: () => void;
}

export const useInvalidateUnreadMessagesCount = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries([MessengerQueryKeys.UNREAD_CONVERSATIONS_COUNT]);
};

export const useUnreadMessagesCount = (): UseUnreadMessagesCountHookResult => {
  const {messengerAccelerate} = useFlags();

  const {
    data: unreadConversationsCount,
    isLoading: isUnreadConversationsCountLoading,
    error: unreadConversationsCountError,
    refetch: refetchUnreadConversationsCount,
  } = useQuery<unknown, ApiError, number>({
    queryKey: [MessengerQueryKeys.UNREAD_CONVERSATIONS_COUNT],
    queryFn: async () => {
      const {data} = await fetchUnreadConversationsCount();

      return data?.count;
    },
    onError: (error) => {
      Sentry.captureException(error);
      callErrorToast({
        content: "An error occurred while loading unread conversations count",
      });
    },
    enabled: !!messengerAccelerate,
  });

  return {
    unreadConversationsCount: unreadConversationsCount || 0,
    isUnreadConversationsCountLoading,
    unreadConversationsCountError,
    refetchUnreadConversationsCount,
  };
};
