import * as Sentry from "@sentry/react";
import {useInfiniteQuery, useQueryClient} from "@tanstack/react-query";
import {useMemo} from "react";

import {
  MessengerQueryKeys,
  PER_PAGE_SIZE_LIMIT,
} from "~components/business-owner/messenger/constants";
import {groupMessagesByDay} from "~components/business-owner/messenger/utils";

import {
  fetchConversationMessages,
  FetchConversationMessagesResponse,
} from "~api/business-owner/messenger";
import {ApiError} from "~types/api";
import {callErrorToast} from "~utils/toasts";

interface UseConversationMessagesParams {
  customerId: string | null;
  perPage?: number;
  page?: number;
}

export const useInvalidateConversationMessages = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries([MessengerQueryKeys.CUSTOMER_MESSAGES]);
};

export const useConversationMessages = ({
  customerId,
  perPage,
}: UseConversationMessagesParams) => {
  const {
    data,
    error,
    isFetched,
    isInitialLoading,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<unknown, ApiError, FetchConversationMessagesResponse>({
    queryKey: [MessengerQueryKeys.CUSTOMER_MESSAGES, customerId],
    queryFn: async ({pageParam = 1}) => {
      const {data} = await fetchConversationMessages(customerId as string, {
        page: pageParam,
        perPage: perPage ?? PER_PAGE_SIZE_LIMIT,
      });
      return data ?? {};
    },
    enabled: !!customerId,
    getNextPageParam: (lastPage: FetchConversationMessagesResponse) => {
      return lastPage?.meta?.hasNextPage ? lastPage?.meta?.currentPage + 1 : undefined;
    },
    onError: (error) => {
      Sentry.captureException(error);
      callErrorToast({content: "An error occurred while loading customer messages"});
    },
  });

  const conversationMessages = useMemo(
    () => data?.pages?.flatMap((page) => page.records.map((message) => message)) ?? [],
    [data]
  );

  const groupedMessages = useMemo(
    () => groupMessagesByDay(conversationMessages),
    [conversationMessages]
  );

  const totalCount = data?.pages?.[0]?.meta?.total ?? 0;
  const currentPage = data?.pages?.[0]?.meta?.currentPage ?? 0;
  const hasNextPage = data?.pages?.[0]?.meta?.hasNextPage ?? false;

  return {
    conversationMessages,
    groupedMessages,
    conversationMessagesTotalCount: totalCount,
    conversationMessagesCurrentPage: currentPage,
    isConversationMessagesHasNextPage: hasNextPage,
    isConversationMessagesLoading: isInitialLoading || isFetchingNextPage,
    isConversationMessagesFetched: isFetched,
    conversationMessagesError: error,
    refetchConversationMessages: refetch,
    fetchNextConversationMessagesPage: fetchNextPage,
  };
};
