import {isEmpty, sortBy} from "lodash";
import pick from "lodash/pick";

import {ShiftTypes} from "~constants";
import {REACT_ENV} from "~utils/config";

import {SERVICES_TYPE} from "../../constants/servicesType";
import {areShiftTimingsAvailable} from "../common/shifts-tab/utils";
import {locationTabs, ServicePricingOption} from "../constants";

const EMPTY_VALUES = ["", null, undefined];
const CENTS_IN_DOLLAR = 100;

export const isLocationDetailsSaveDisabled = (location, needsRegions) => {
  const fieldKeys = ["name", "phoneNumber", "address", "city", "state", "zipCode"];
  // If needsRegions is false, then we can ignore districtId.
  // Else, we need to check for that as well.
  if (needsRegions) {
    fieldKeys.push("districtId");
  }
  const fieldValues = Object.values(pick(location, fieldKeys));
  return EMPTY_VALUES.some((val) => fieldValues.includes(val));
};

export const getLocationTabsList = (location) => {
  return location.type === "RESIDENTIAL" ||
    (!location.hasDeliveryEnabled && REACT_ENV === "production")
    ? locationTabs.filter((t) => t.value !== "delivery-settings")
    : locationTabs;
};

export const curateShiftsAndTimings = (shifts, opts = {}) => {
  const {
    name = "Shift",
    addNewShift = true,
    type = ShiftTypes.SHIFT,
    overlapping = false,
  } = opts;

  let curatedShifts = [...shifts].sort((s1, s2) => s1?.id - s2?.id);

  if (addNewShift) {
    curatedShifts.push({
      name: `+ ${name} ${curatedShifts.length + 1}`,
      type,
      timings: [],
    });
  }

  curatedShifts = curatedShifts.map((shift) => {
    const isOldShift = Boolean(shift.id);
    const newTimings = [1, 2, 3, 4, 5, 6, 0].map((day) => {
      const existingTiming = shift.timings.find((t) => Number(t?.day) === day);

      return (
        existingTiming || {
          day,
          // If it's an old shift, it means they manually set it to false.
          isActive: !isOldShift,
          startTime: null,
          endTime: null,
          deliveryTimingSettings: {maxStops: null, serviceType: "ALL"},
        }
      );
    });

    return {
      ...shift,
      timings: newTimings,
    };
  });

  if (!overlapping) {
    // Need to loop again to send the latest shifts data that was setup above
    // This will set isActive key by also checking with the availablity of time.
    curatedShifts = curatedShifts.map((shift, shiftIdx) => {
      return {
        ...shift,
        timings: shift.timings.map((timing) => ({
          ...timing,
          isActive:
            timing.isActive &&
            areShiftTimingsAvailable(
              curatedShifts,
              shiftIdx,
              Number(timing.day) === 0 ? 6 : Number(timing.day) - 1
            ),
        })),
      };
    });
  }

  return curatedShifts;
};

export const priceToDollars = (price) =>
  Math.ceil(Math.abs(Number(price))) / CENTS_IN_DOLLAR;

export const centsToDollarsDisplay = (amount) => {
  return priceToDollars(amount).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const toDollars = (amount) => {
  return `$${Number(amount || 0)?.toFixed(2) || "0.00"}`;
};

const getServicePricing = ({hasMinPrice, storePrice, isPerPound}) =>
  `(${hasMinPrice ? "min + " : ""}${toDollars(storePrice)} / ${
    isPerPound ? "lb" : "unit"
  })`;

const getFormattedServicesByCategoryCents20 = (availableServices) => {
  const formattedServices = availableServices
    .map(({services}) => services)
    .flat()
    .map(
      ({
        name,
        id,
        hasMinPrice,
        prices: [{storePrice, isDeliverable}],
        pricingStructure: {type: pricingType},
      }) => ({
        label: name,
        value: id,
        metaInfo: getServicePricing({
          hasMinPrice,
          storePrice,
          isPerPound: pricingType === SERVICES_TYPE.PER_POUND,
        }),
        isDeliverable,
      })
    );

  return formattedServices;
};

export const getActiveOnlineOrderServices = (services) =>
  getFormattedServicesByCategoryCents20(services).filter(
    (service) => service?.isDeliverable
  );

const getInActiveOnlineOrderServices = (services, selectedServicesOptions) => {
  const filteredServices = services.filter(
    (i) => i.categoryType !== SERVICES_TYPE.DRY_CLEANING
  );
  return getFormattedServicesByCategoryCents20(filteredServices).filter(
    ({value}) => !selectedServicesOptions?.find((option) => option?.value === value)
  );
};

const filterByCategory = (
  serviceCategory,
  availableServices,
  selectedServicesOptions,
  checkForIsDeliverable = true
) => {
  const serviceCategoryCount = Array.isArray(serviceCategory)
    ? serviceCategory.length
    : 1;

  if (!availableServices?.length) {
    return [];
  }
  let returnArr = [];
  for (let i = 0; i < serviceCategoryCount; i++) {
    const currServiceCategory = Array.isArray(serviceCategory)
      ? serviceCategory[i]
      : serviceCategory;
    const isPerPound = currServiceCategory === "PER_POUND";
    const tempArr = (
      availableServices?.find(({category}) => category === currServiceCategory) || {
        services: [],
      }
    ).services
      ?.filter(
        ({prices: [{isFeatured, isDeliverable}]}) =>
          isFeatured && (isDeliverable || !checkForIsDeliverable)
      )
      ?.map(({name, id, hasMinPrice, prices: [{storePrice}]}) => {
        return {
          label: name,
          value: id,
          metaInfo: getServicePricing({
            hasMinPrice,
            storePrice,
            isPerPound,
          }),
        };
      })
      ?.filter(
        ({value}) => !selectedServicesOptions?.find((option) => option?.value === value)
      );

    returnArr.push(...tempArr);
  }
  const sortedReturnArr = sortBy(returnArr, [(o) => o.label.toLowerCase()]);
  return sortedReturnArr;
};

export const groupedServiceOptions = (availableServices, selectedServicesOptions) => {
  return [
    {
      label: "Wash & Fold",
      options: getInActiveOnlineOrderServices(availableServices, selectedServicesOptions),
    },
  ];
};

export const buildDeliveryPricingPayload = (
  zones,
  deliveryTier,
  selectedServicesForRetailPricing,
  deliveryPriceType,
  deliverySettings
) => {
  const {ownDriverDeliverySettings, onDemandDeliverySettings} = deliverySettings;

  let payload = {};
  payload.deliveryPriceType = deliveryPriceType;
  const isOwnDriverDeliverySettingsActiveAndHasNoZones =
    !ownDriverDeliverySettings?.hasZones && ownDriverDeliverySettings?.active;
  const isOwnDriverDeliverySettingsInActiveAndHasZones =
    ownDriverDeliverySettings?.hasZones && !ownDriverDeliverySettings?.active;

  const isOwnDriverDeliverySettingsInActiveAndHasNoZones =
    !ownDriverDeliverySettings?.hasZones && !ownDriverDeliverySettings?.active;
  const isOwnDriverDeliverySettingsActiveAndHasZones =
    ownDriverDeliverySettings?.hasZones && ownDriverDeliverySettings?.active;

  const isDeliveryTierIdRequired =
    isOwnDriverDeliverySettingsActiveAndHasNoZones ||
    (onDemandDeliverySettings?.active &&
      (isOwnDriverDeliverySettingsInActiveAndHasZones ||
        isOwnDriverDeliverySettingsInActiveAndHasNoZones));

  if (
    deliveryPriceType === ServicePricingOption.deliveryTierPricing &&
    isOwnDriverDeliverySettingsActiveAndHasZones
  ) {
    payload.deliveryTierId = deliveryTier?.value;
    payload.zones = zones.map((zone) => {
      return {
        id: zone?.id,
        deliveryTierId: zone?.deliveryTier.value,
      };
    });
    payload.offerDryCleaningForDelivery = deliveryTier?.offerDryCleaningForDeliveryTier;
    payload.dryCleaningDeliveryPriceType = ServicePricingOption.deliveryTierPricing;
  } else if (
    deliveryPriceType === ServicePricingOption.deliveryTierPricing &&
    isDeliveryTierIdRequired
  ) {
    payload.deliveryTierId = deliveryTier?.value;
    payload.offerDryCleaningForDelivery = deliveryTier?.offerDryCleaningForDeliveryTier;
    payload.dryCleaningDeliveryPriceType = ServicePricingOption.deliveryTierPricing;
  } else if (deliveryPriceType === ServicePricingOption.storeRetailPricingOption) {
    payload.deliveryServiceIds = selectedServicesForRetailPricing
      .map((e) => {
        return e?.value;
      })
      .filter(Number);
  }
  if (
    deliveryPriceType === ServicePricingOption.storeRetailPricingOption &&
    isEmpty(payload?.deliveryServiceIds)
  ) {
    return {
      payload,
      error:
        "Please select atleast one service that you want to offer for pickup & delivery",
    };
  } else if (
    deliveryPriceType === ServicePricingOption.deliveryTierPricing &&
    isDeliveryTierIdRequired &&
    !payload?.deliveryTierId
  ) {
    return {
      payload,
      error: "Please select a Delivery Tier!",
    };
  } else if (
    deliveryPriceType === ServicePricingOption.deliveryTierPricing &&
    isOwnDriverDeliverySettingsActiveAndHasZones &&
    payload?.zones.filter((zone) => zone?.deliveryTierId).length < payload?.zones?.length
  ) {
    return {
      payload,
      error: "Please select a Delivery Tier for each zone!",
    };
  }
  return {payload, error: null};
};
