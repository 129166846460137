export const MAX_TOP_ITEMS = 5;

export const colorValues = {
  primaryBlue: "#3790F4",
  accentDarkBlue: "#0567D5",
  accentGreen: "#5DC435",
  accentGrey: "#303651",
  accentOrange: "#FF9900",
  accentRed: "#DC562E",
  accentDarkRed: "#B20000",
  accentTurquoise: "#099FAA",
  accentDarkGreen: "#1D5B03",
  accentPurple: "#797EF6",
  accentMagenta: "#9423BC",
  accentPink: "#F600FA",
};

export const chartColors = [
  colorValues.primaryBlue,
  colorValues.accentGrey,
  colorValues.accentOrange,
  colorValues.accentRed,
  colorValues.accentGreen,
  colorValues.accentDarkBlue,
  colorValues.accentDarkRed,
  colorValues.accentTurquoise,
  colorValues.accentDarkGreen,
  colorValues.accentPurple,
  colorValues.accentMagenta,
  colorValues.accentPink,
];

export const REVENUE_SECTION_KEY = "REVENUE";
export const ORDERS_SECTION_KEY = "ORDERS";
export const CUSTOMERS_SECTION_KEY = "CUSTOMERS";
export const VOLUME_SECTION_KEY = "VOLUME";
export const PROMOTIONS_SECTION_KEY = "PROMOTIONS";
export const PRODUCTS_SECTION_KEY = "PRODUCTS";
export const DELIVERY_SECTION_KEY = "DELIVERY";
export const MACHINES_SECTION_KEY = "MACHINES";
export const CONNECT_SECTION_KEY = "CONNECT";

export const WIDGETS_SESSION_STORAGE_KEY = "analyticsWidgetSections";

export const allDashboardSections = [
  {
    functionName: "updateSalesSectionVisibility",
    name: "Sales",
    key: REVENUE_SECTION_KEY,
  },
  {
    functionName: "updateOrderSectionVisibility",
    name: "Orders",
    key: ORDERS_SECTION_KEY,
  },
  {
    functionName: "updateCustomerSectionVisibility",
    name: "Customers",
    key: CUSTOMERS_SECTION_KEY,
  },
  {
    functionName: "updateVolumeSectionVisibility",
    name: "Volume",
    key: VOLUME_SECTION_KEY,
  },
  {
    functionName: "updatePromotionsSectionVisibility",
    name: "Promotions",
    key: PROMOTIONS_SECTION_KEY,
  },
  {
    functionName: "updateProductsSectionVisibility",
    name: "Products/Services",
    key: PRODUCTS_SECTION_KEY,
  },
  {
    functionName: "updateDeliverySectionVisibility",
    name: "Order Delivery",
    key: DELIVERY_SECTION_KEY,
  },
  {
    functionName: "updateMachinesSectionVisibility",
    name: "Machines",
    key: MACHINES_SECTION_KEY,
  },
  {
    functionName: "updateConnectSectionVisibility",
    name: "Machines",
    key: CONNECT_SECTION_KEY,
  },
];

export const availableSections = [
  REVENUE_SECTION_KEY,
  ORDERS_SECTION_KEY,
  CUSTOMERS_SECTION_KEY,
  VOLUME_SECTION_KEY,
  PROMOTIONS_SECTION_KEY,
  PRODUCTS_SECTION_KEY,
  CONNECT_SECTION_KEY,
  // DELIVERY_SECTION_KEY,
  // MACHINES_SECTION_KEY,
];

export const MACHINE_TYPE_TABS = [
  {label: "All", value: "all"},
  {label: "Washers", value: "washers"},
  {label: "Dryers", value: "dryers"},
];

export const centerTextLabelColor = "#aeb0b1";
export const centerTextValueColor = "#3790f4";
