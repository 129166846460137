import {PayloadAction} from "@reduxjs/toolkit";

import actionTypes from "~actionTypes";
import {createNamespacer, createReducer, ReducerHandlers} from "~utils/reducers";

const nameSpace = "BO-CUSTOMERS";

const custNameSpacer = createNamespacer(nameSpace);
const custAT = actionTypes.businessOwner.customers;

export interface CustomersState {
  isFiltersPopoutOpen: boolean;
}

const initialState: CustomersState = {
  isFiltersPopoutOpen: false,
};

const handlers: ReducerHandlers<CustomersState> = {
  [custNameSpacer(custAT.SET_FILTERS_POPOUT_VISIBILITY)]: (
    state,
    action: PayloadAction<boolean>
  ) => {
    return {
      ...state,
      isFiltersPopoutOpen: action.payload,
    };
  },
};

export default createReducer(initialState, handlers, [nameSpace]);
