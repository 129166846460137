import {useDispatch, useSelector} from "react-redux";

import {GlobalState} from "~reducers";
import {
  ConversationsListSearchParamsType,
  messengerAT,
  messengerNameSpacer,
} from "~reducers/businessOwner/messenger";

export const useConversationsListSearchParams = () => {
  const dispatch = useDispatch();
  const {page, q, unread} = useSelector(
    (state: GlobalState) => state.businessOwner.messenger.conversationsListSearchParams
  );

  const setConversationsListSearchParams = (
    params: Partial<ConversationsListSearchParamsType>
  ) => {
    dispatch({
      type: messengerNameSpacer(messengerAT.SET_CONVERSATIONS_LIST_SEARCH_PARAMS),
      payload: params,
    });
  };

  return {page, q, unread, setConversationsListSearchParams};
};
