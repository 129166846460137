import actionTypes from "~actionTypes";
import {createNamespacer, createReducer} from "~utils/reducers";

const nameSpace = "INVOICES";

export const invNameSpacer = createNamespacer(nameSpace);
export const invAT = actionTypes.businessOwner.invoices;

const initialState = {
  isCustomerListChanged: true,
  shouldShowInvoices: false,
};

const handlers = {
  [invNameSpacer(invAT.TOGGLE_CUSTOMER_LIST_CHANGED)]: (state) => ({
    ...state,
    isCustomerListChanged: !state.isCustomerListChanged,
  }),
  [invNameSpacer(invAT.SET_SHOW_INVOICES)]: (state, {payload}) => ({
    ...state,
    shouldShowInvoices: payload,
  }),
};

export default createReducer(initialState, handlers, [nameSpace]);
