import {combineReducers} from "redux";

import analyticsDashboard from "./analyticsDashboard";
import customers from "./customers";
import dashboard from "./dashboard";
import globalSettings from "./globalSettings";
import invoices from "./invoices";
import machines from "./machines";
import messenger from "./messenger";

export default combineReducers({
  globalSettings,
  analyticsDashboard,
  dashboard,
  machines,
  customers,
  invoices,
  messenger,
});
