import * as Sentry from "@sentry/react";
import {useQuery} from "@tanstack/react-query";
import {useFlags} from "launchdarkly-react-client-sdk";

import {MessengerQueryKeys} from "~components/business-owner/messenger/constants";

import {
  fetchMessengerConnectionUrl,
  FetchMessengerConnectionUrlResponse,
} from "~api/business-owner/messenger";
import {ApiError} from "~types/api";
import {callErrorToast} from "~utils/toasts";

const useMessengerConnectionUrl = () => {
  const {messengerAccelerate} = useFlags();

  return useQuery<unknown, ApiError, FetchMessengerConnectionUrlResponse | null>({
    queryKey: [MessengerQueryKeys.MESSENGER_WEBHOOK_CONNECTION_URL],
    queryFn: async () => {
      const {data} = await fetchMessengerConnectionUrl();
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);
      callErrorToast({
        content: "An error occurred while fetching the messenger connection URL",
      });
    },
    enabled: !!messengerAccelerate,
  });
};

export default useMessengerConnectionUrl;
