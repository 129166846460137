import {createNamespacer, createReducer} from "~utils/reducers";

import actionTypes from "../actionTypes";

const nameSpace = "LIST_FILTERS_BAR";

export const lfNS = createNamespacer(nameSpace);
export const lfAT = actionTypes.listFiltersBar;

const initialState = {
  filters: {
    activeTab: "",
    searchString: null,
  },
  shouldUpdateWarning: false,
};

const handlers = {
  [lfNS(lfAT.CHANGE_FILTERS)]: (state, {payload}) => ({
    ...state,
    filters: {
      ...state.filters,
      activeTab: payload.activeTab,
      searchString: payload.searchString,
    },
  }),
  [lfNS(lfAT.SET_ACTIVE_TAB)]: (state, {payload}) => ({
    ...state,
    filters: {
      ...state.filters,
      activeTab: payload,
      searchString: null,
    },
  }),
  [lfNS(lfAT.SET_SEARCH_STRING)]: (state, {payload}) => ({
    ...state,
    filters: {
      ...state.filters,
      searchString: payload,
    },
  }),
  [lfNS(lfAT.SET_UPDATE_WARNING)]: (state, {payload}) => ({
    ...state,
    shouldUpdateWarning: payload,
  }),
};

export default createReducer(initialState, handlers, [nameSpace]);
