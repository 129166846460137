// Survicate (https://survicate.com/) is used for NPS surveys
// Survicate is initialized in every environment - it is configured on the survicate side to whitelist only the production domain

// see global-env.d.ts for the type definitions of window._sva, window._svc, and window._svd - we aren't using all of the properties possible. if we need to add something to the types, it is okay.
// more info about the javacript API can be found here: https://developers.survicate.com/javascript/

type SurvicateTraits = {
  email?: string;
  uid: string;
  first_name?: string;
  last_name?: string;
  businessId?: number;
  businessName?: string;
  businessCreatedAt?: string;
};

export async function setSurvicateTraits({
  email,
  uid,
  first_name,
  last_name,
  businessId,
  businessName,
  businessCreatedAt,
}: SurvicateTraits) {
  window?._sva?.setVisitorTraits({
    email,
    uid,
    first_name,
    last_name,
    businessId,
    businessName,
    businessCreatedAt,
  });
}
