import {combineReducers} from "redux";

import businessOwner from "./businessOwner";
import forgotPassword from "./forgot-password";
import listFiltersBar from "./listFiltersBar";
import login from "./login";
import passwordReset from "./password-reset";
import session from "./session";

const rootReducer = combineReducers({
  login,
  passwordReset,
  forgotPassword,
  session,
  businessOwner,
  listFiltersBar,
});

export type GlobalState = ReturnType<typeof rootReducer>;
export default rootReducer;
