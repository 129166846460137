import {PayloadAction} from "@reduxjs/toolkit";

import {MESSENGER_DEFAULT_PAGE_NUMBER} from "~components/business-owner/messenger/constants";

import actionTypes from "~actionTypes";
import {createNamespacer, createReducer, ReducerHandlers} from "~utils/reducers";

export type ConversationsListSearchParamsType = {
  page: number;
  q: string;
  unread: boolean;
};

export type MessengerState = {
  conversationsListSearchParams: ConversationsListSearchParamsType;
};

const messengerNameSpace = "BO-MESSENGER" as const;

export const messengerNameSpacer = createNamespacer(messengerNameSpace);
export const messengerAT = actionTypes.businessOwner.messenger;

const initialState: MessengerState = {
  conversationsListSearchParams: {
    page: MESSENGER_DEFAULT_PAGE_NUMBER,
    q: "",
    unread: false,
  },
};

const handlers: ReducerHandlers<MessengerState> = {
  [messengerNameSpacer(messengerAT.SET_CONVERSATIONS_LIST_SEARCH_PARAMS)]: (
    state,
    action: PayloadAction<Partial<ConversationsListSearchParamsType>>
  ) => {
    return {
      ...state,
      conversationsListSearchParams: {
        ...state.conversationsListSearchParams,
        ...action.payload,
      },
    };
  },
};

export default createReducer(initialState, handlers, [messengerNameSpace]);
